// src/components/CreateDatasetPage.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../auth/auth';
import { unparse } from 'papaparse';

function CreateDatasetPage() {
  const [datasetName, setDatasetName] = useState('');
  const [columns, setColumns] = useState([]);
  const [newColumnName, setNewColumnName] = useState('');
  const [dataRows, setDataRows] = useState([]);
  const [isModified, setIsModified] = useState(false);

  const navigate = useNavigate();

  // Add a new column
  const handleAddColumn = () => {
    if (newColumnName && !columns.includes(newColumnName)) {
      setColumns([...columns, newColumnName]);
      setNewColumnName('');
      setIsModified(true);
    }
  };

  // Remove a column
  const handleRemoveColumn = (columnName) => {
    const updatedColumns = columns.filter((col) => col !== columnName);
    setColumns(updatedColumns);
    // Remove the column from each data row
    const updatedRows = dataRows.map((row) => {
      const { [columnName]: _, ...rest } = row;
      return rest;
    });
    setDataRows(updatedRows);
    setIsModified(true);
  };

  // Handle cell changes
  const handleCellChange = (e, rowIndex, columnName) => {
    const newValue = e.target.value;
    const updatedRows = [...dataRows];
    updatedRows[rowIndex][columnName] = newValue;
    setDataRows(updatedRows);
    setIsModified(true);
  };

  // Add a new row
  const handleAddRow = () => {
    const newRow = {};
    columns.forEach((column) => {
      newRow[column] = '';
    });
    setDataRows([...dataRows, newRow]);
    setIsModified(true);
  };

  // Delete a row
  const handleDeleteRow = (rowIndex) => {
    const updatedRows = dataRows.filter((_, index) => index !== rowIndex);
    setDataRows(updatedRows);
    setIsModified(true);
  };

  // Save the dataset
  const handleSaveDataset = async () => {
    if (!datasetName) {
      alert('Please provide a dataset name.');
      return;
    }
    if (columns.length === 0) {
      alert('Please add at least one column.');
      return;
    }

    try {
      const csvData = unparse(dataRows, { columns });
      const blob = new Blob([csvData], { type: 'text/csv' });
      const formData = new FormData();
      formData.append('file', blob, `${datasetName}.csv`);

      // Use the existing upload endpoint
      await api.post('/api_bingo/upload_csv/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      alert('Dataset created successfully!');
      navigate('/datasets');
    } catch (error) {
      console.error('Error creating dataset:', error);
      alert('Failed to create dataset.');
    }
  };

  // Cancel and navigate back
  const handleCancel = () => {
    if (isModified) {
      if (window.confirm('Are you sure you want to discard changes?')) {
        navigate('/datasets');
      }
    } else {
      navigate('/datasets');
    }
  };

  return (
<div className="p-8 bg-blue-400 min-h-screen flex flex-col items-center">
  <h1 className="text-3xl font-bold mb-6 text-sky-900">Create New Dataset</h1>

  {/* Dataset Name Input */}
  <div className="mb-6 w-full max-w-lg">
    <label htmlFor="datasetName" className="block text-lg font-semibold text-gray-700 mb-2">
      Dataset Name:
    </label>
    <input
      type="text"
      id="datasetName"
      value={datasetName}
      onChange={(e) => setDatasetName(e.target.value)}
      className="border border-gray-300 rounded w-full px-3 py-2 focus:outline-none focus:ring focus:ring-sky-300"
      placeholder="Enter dataset name"
    />
  </div>

  {/* Column Management */}
  <div className="mb-6 w-full max-w-lg">
    <h2 className="text-2xl font-semibold text-sky-900 mb-4">Columns (Modes)</h2>
    <div className="flex space-x-4 mb-4">
      <input
        type="text"
        placeholder="New column name"
        value={newColumnName}
        onChange={(e) => setNewColumnName(e.target.value)}
        className="border border-gray-300 rounded w-full px-3 py-2 focus:outline-none focus:ring focus:ring-sky-300"
      />
      <button
        className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700"
        onClick={handleAddColumn}
      >
        Add Column
      </button>
    </div>
    <ul className="list-disc list-inside bg-white shadow-md rounded p-4">
      {columns.map((column) => (
        <li key={column} className="flex justify-between items-center py-2 text-gray-700">
          <span className="font-medium">{column}</span>
          <button
            className="text-red-600 hover:text-red-800"
            onClick={() => handleRemoveColumn(column)}
          >
            Remove
          </button>
        </li>
      ))}
    </ul>
  </div>

  {/* Buttons for adding rows and saving */}
  <div className="mb-6 flex space-x-4">
    <button
      className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
      onClick={handleAddRow}
      disabled={columns.length === 0}
    >
      Add Row
    </button>
    {isModified && (
      <>
        <button
          className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          onClick={handleSaveDataset}
        >
          Save Dataset
        </button>
        <button
          className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
          onClick={handleCancel}
        >
          Cancel
        </button>
      </>
    )}
  </div>

  {/* Data Rows Table */}
  {columns.length > 0 && (
    <div className="w-full max-w-4xl">
      <h2 className="text-2xl font-semibold text-sky-900 mb-4">Words (Data Rows)</h2>
      <table className="table-auto w-full bg-white shadow-md rounded overflow-hidden">
        <thead>
          <tr className="bg-sky-200 text-left">
            {columns.map((column) => (
              <th key={column} className="px-4 py-3 text-gray-700">{column}</th>
            ))}
            <th className="px-4 py-3 text-gray-700">Actions</th>
          </tr>
        </thead>
        <tbody>
          {dataRows.map((row, rowIndex) => (
            <tr key={rowIndex} className="even:bg-gray-50">
              {columns.map((column) => (
                <td key={column} className="px-4 py-2 border-t">
                  <input
                    type="text"
                    value={row[column]}
                    onChange={(e) => handleCellChange(e, rowIndex, column)}
                    className="w-full border px-3 py-1 rounded focus:outline-none focus:ring focus:ring-sky-300"
                  />
                </td>
              ))}
              <td className="px-4 py-2 border-t text-center">
                <button
                  className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
                  onClick={() => handleDeleteRow(rowIndex)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )}
</div>

  );
}

export default CreateDatasetPage;
