import React from 'react';
import { Link } from 'react-router-dom';
import { FaDatabase, FaGamepad, FaClipboardList, FaInfoCircle } from 'react-icons/fa'; // Importing icons
import { isAuthenticated } from '../auth/auth'; // To check if the user is logged in

function MainScreen() {
  const isLoggedIn = isAuthenticated(); // Check if the user is logged in

  return (
    <div className="bg-blue-400 min-h-screen flex flex-col items-center">
      {/* Hero Section */}
      <div className="w-full bg-cover bg-center h-80" style={{ backgroundImage: "url('/bingo-hero.webp')" }}>
        <div className="h-full flex justify-center items-center bg-blue-400 bg-opacity-40">
          
        </div>
      </div>
      <h1 className="text-white text-5xl font-extrabold p-4">Welcome to Bingo App</h1>
      {/* Card Buttons */}
      <div className="w-full max-w-6xl mt-12 grid grid-cols-2 md:grid-cols-4 gap-6 p-6">
        {/* Datasets Button */}
        <div className="bg-white p-8 shadow-lg rounded-lg text-center hover:shadow-2xl transition duration-300">
          <Link to={isLoggedIn ? "/datasets" : "/login"} className="text-sky-900">
            <FaDatabase className="text-5xl mb-4 mx-auto" />
            <h2 className="text-2xl font-bold mb-4">Manage Datasets</h2>
            <p className="text-gray-700">Upload, create, and manage datasets.</p>
          </Link>
        </div>

        {/* Bingo Game Button */}
        <div className="bg-white p-8 shadow-lg rounded-lg text-center hover:shadow-2xl transition duration-300">
          <Link to={isLoggedIn ? "/bingo-app" : "/login"} className="text-sky-900">
            <FaGamepad className="text-5xl mb-4 mx-auto" />
            <h2 className="text-2xl font-bold mb-4">Bingo Game</h2>
            <p className="text-gray-700">Play the Bingo Game!</p>
          </Link>
        </div>

        {/* Card Generator Button */}
        <div className="bg-white p-8 shadow-lg rounded-lg text-center hover:shadow-2xl transition duration-300">
          <Link to={isLoggedIn ? "/card-generator" : "/login"} className="text-sky-900">
            <FaClipboardList className="text-5xl mb-4 mx-auto" />
            <h2 className="text-2xl font-bold mb-4">Bingo Card Generator</h2>
            <p className="text-gray-700">Create and download bingo cards.</p>
          </Link>
        </div>
        {/* Card Info Button */}
        <div className="bg-white p-8 shadow-lg rounded-lg text-center hover:shadow-2xl transition duration-300">
          <Link to={isLoggedIn ? "/info" : "/login"} className="text-sky-900">
            <FaInfoCircle className="text-5xl mb-4 mx-auto" />
            <h2 className="text-2xl font-bold mb-4">How to start?</h2>
            <p className="text-gray-700">Step by step info</p>
          </Link>
        </div>

      </div>
    </div>
  );
}

export default MainScreen;
