import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainScreen from './components/MainScreen';
import BingoApp from './components/BingoApp';
import BingoCardGenerator from './components/BingoCardGenerator';
import DatasetsPage from './components/DatasetsPage';
import DatasetDetailPage from './components/DatasetDetailPage';
import CreateDatasetPage from './components/CreateDatasetPage';
import Navbar from './components/Navbar';
import LoginPage from './components/LoginPage';
import PrivateRoute from './auth/PrivateRoute';
import InfoPage from './components/InfoPage';

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));

  const handleLoginSuccess = (accessToken) => {
    localStorage.setItem('token', accessToken);
    setToken(accessToken);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setToken(null);
  };

  return (
    <Router>
      <Navbar token={token} onLogout={handleLogout} />
      <div className="bg-blue-400 min-h-screen flex flex-col">
        <Routes>
          <Route path="/login" element={<LoginPage onLoginSuccess={handleLoginSuccess} />} />
          <Route path="/" element={<MainScreen />} />
          
          {/* Protected routes */}
          <Route
            path="/datasets"
            element={
              <PrivateRoute isAuthenticated={!!token}>
                <DatasetsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/api_bingo/dataset/:fileName"
            element={
              <PrivateRoute isAuthenticated={!!token}>
                <DatasetDetailPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/bingo-app"
            element={
              <PrivateRoute isAuthenticated={!!token}>
                <BingoApp />
              </PrivateRoute>
            }
          />
          <Route
            path="/card-generator"
            element={
              <PrivateRoute isAuthenticated={!!token}>
                <BingoCardGenerator />
              </PrivateRoute>
            }
          />
          <Route
            path="/create-dataset"
            element={
              <PrivateRoute isAuthenticated={!!token}>
                <CreateDatasetPage />
              </PrivateRoute>
            }
          />          
          <Route
            path="/info"
            element={
              <PrivateRoute isAuthenticated={!!token}>
                <InfoPage />
              </PrivateRoute>
            }
          />               
        </Routes>
      </div>
    </Router>
  );
}

export default App;
