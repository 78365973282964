// src/components/BingoApp.js

import React, { useState, useEffect, useRef } from 'react';
import { parse } from 'papaparse';
import VerbFormSelector from './VerbFormSelector';
import DrawButton from './DrawButton';
import Timer from './Timer';
import PlayButton from './PlayButton';
import jsPDF from 'jspdf';
import { api } from '../auth/auth'; // Adjusted import


function BingoApp() {
  const [datasets, setDatasets] = useState([]);
  const [selectedDataset, setSelectedDataset] = useState('');
  const [columns, setColumns] = useState([]);
  const [selectedForm, setSelectedForm] = useState('');
  const [dataRows, setDataRows] = useState([]);
  const [availableWords, setAvailableWords] = useState([]);
  const [drawnWords, setDrawnWords] = useState([]);
  const [timerSeconds, setTimerSeconds] = useState(15);
  const [gameStarted, setGameStarted] = useState(false);
  const [drawCounter, setDrawCounter] = useState(1);

  const popupWindowRef = useRef(null);

  const fetchDatasets = async () => {
    try {
      const response = await api.get('/api_bingo/datasets/');
      setDatasets(response.data.datasets);
    } catch (error) {
      console.error('Error fetching datasets:', error);
    }
  };

  useEffect(() => {
    fetchDatasets();
  }, []);

  // Load the selected dataset and extract columns
  useEffect(() => {
    if (selectedDataset) {
      api
        .get(`/api_bingo/datasets/${selectedDataset}/`, { responseType: 'blob' })
        .then((response) => response.data.text())
        .then((data) => {
          parse(data, {
            header: true,
            complete: (result) => {
              setDataRows(result.data);
              setColumns(result.meta.fields);
              setSelectedForm(''); // Reset the selected form
            },
          });
        })
        .catch((error) => {
          console.error('Error loading dataset:', error);
        });
    } else {
      setColumns([]);
      setDataRows([]);
      setSelectedForm('');
    }
  }, [selectedDataset]);

  const startGame = () => {
    if (!selectedForm) {
      alert('Please select a mode to start the game.');
      return;
    }

    const confirmation = window.confirm('Are you sure you want to start a new game? This will reset the current game.');

    if (confirmation) {
      const shuffledWords = [...dataRows]
        .sort(() => Math.random() - 0.5)
        .map((row) => row[selectedForm])
        .filter(Boolean);

      setAvailableWords(shuffledWords);
      setDrawnWords([]);
      setGameStarted(true);
      setDrawCounter(1);

      if (popupWindowRef.current && !popupWindowRef.current.closed) {
        popupWindowRef.current.close();
        popupWindowRef.current = null;
      }
    }
  };

  const handleDrawWord = () => {
    if (availableWords.length === 0) {
      alert('No more words to draw!');
      return;
    }

    const drawnWord = availableWords[0];

    setAvailableWords(availableWords.slice(1));
    setDrawnWords([...drawnWords, { word: drawnWord, drawOrder: drawCounter }]);
    setDrawCounter(drawCounter + 1);

    if (!popupWindowRef.current || popupWindowRef.current.closed) {
      popupWindowRef.current = window.open('', 'DrawnWordWindow', 'width=600,height=400');
      popupWindowRef.current.onbeforeunload = () => {
        popupWindowRef.current = null;
      };
    }

    if (popupWindowRef.current) {
      const popupDocument = popupWindowRef.current.document;
      popupDocument.open();
      popupDocument.write(`
        <html>
          <head>
            <title>Drawn Word</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;
                margin: 0;
              }
              h1 {
                font-size: 5rem;
                color: #333;
              }
            </style>
          </head>
          <body>
            <h1>${drawnWord}</h1>
          </body>
        </html>
      `);
      popupDocument.close();
    }
  };

  const handleSaveAsPDF = () => {
    if (drawnWords.length === 0) {
      alert('No words have been drawn yet!');
      return;
    }

    const doc = new jsPDF();

    // Add the title
    doc.setFontSize(16);
    doc.text('Bingo Game Results', 14, 20);

    // Add date and time
    const currentDate = new Date();
    doc.setFontSize(12);
    doc.text(`Date: ${currentDate.toLocaleDateString()}`, 14, 30);
    doc.text(`Time: ${currentDate.toLocaleTimeString()}`, 14, 37);

    // Add game mode
    doc.text(`Game Mode: ${selectedForm}`, 14, 44);

    // Add drawn words
    doc.text('Drawn Words:', 14, 54);
    let yPosition = 64;
    drawnWords.forEach(({ word, drawOrder }) => {
      doc.text(`${drawOrder}. ${word}`, 14, yPosition);
      yPosition += 7;
      if (yPosition > 280) {
        doc.addPage();
        yPosition = 20;
      }
    });

    // Save the PDF
    doc.save(`Bingo_Game_Results_${currentDate.toISOString()}.pdf`);
  };

  return (
<div className="bg-blue-400 min-h-screen p-10 flex flex-col items-center">
  <h1 className="text-3xl font-bold mb-6 text-sky-900">Bingo Game</h1>

  {/* Dataset selection */}
  <div className="mb-6 w-full max-w-md">
    <label htmlFor="datasetSelector" className="block text-lg font-semibold text-gray-700 mb-2">
      Select Dataset:
    </label>
    <select
      id="datasetSelector"
      value={selectedDataset}
      onChange={(e) => setSelectedDataset(e.target.value)}
      className="border border-gray-300 rounded w-full px-3 py-2 focus:outline-none focus:ring focus:ring-sky-300"
    >
      <option value="">--Select Dataset--</option>
      {datasets.map((dataset) => (
        <option key={dataset.file_name} value={dataset.file_name}>
          {dataset.display_name}
        </option>
      ))}
    </select>
  </div>

  {/* Mode (column) selection */}
  {columns.length > 0 && (
    <div className="mb-6 w-full max-w-md">
      <VerbFormSelector options={columns} setSelectedForm={setSelectedForm} />
    </div>
  )}

  {/* Start Game button */}
  <button
    onClick={startGame}
    className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow hover:bg-blue-700 transition-all duration-200 mb-4"
    disabled={!selectedForm}
  >
    Start New Game
  </button>

  {/* Timer input field */}
  <div className="mb-6 w-full max-w-md flex items-center">
    <label htmlFor="timerInput" className="text-lg font-semibold text-gray-700 mr-4">Set Timer (seconds):</label>
    <input
      id="timerInput"
      type="number"
      min="1"
      value={timerSeconds}
      onChange={(e) => setTimerSeconds(Number(e.target.value))}
      className="border border-gray-300 rounded w-full px-3 py-2 focus:outline-none focus:ring focus:ring-sky-300"
    />
  </div>

  {/* Draw Word and Save Results buttons */}
  <div className="mb-8 flex space-x-4">
    <DrawButton onDraw={handleDrawWord} disabled={!gameStarted} />
    <button
      onClick={handleSaveAsPDF}
      disabled={!gameStarted || drawnWords.length === 0}
      className="bg-purple-600 text-white py-2 px-4 rounded-lg shadow hover:bg-purple-700 transition-all duration-200"
    >
      Save Results as PDF
    </button>
  </div>

  {/* Timer */}
  <Timer initialTime={timerSeconds} onReset={() => setTimerSeconds(15)} />

  {/* Word Lists */}
  <div className="flex w-full justify-center mt-8 space-x-8">
    {/* Available words */}
    <div className="w-1/2 h-[50vh] overflow-y-auto bg-white shadow-md rounded-lg p-4">
      <h3 className="text-xl font-bold text-sky-900 mb-4 text-center">Available Words</h3>
      <ul>
        {availableWords.length > 0 && availableWords.map((word, index) => (
          <li key={index} className="p-2 text-lg border-b border-gray-200">
            {availableWords.length - index}. {word}
          </li>
        ))}
      </ul>
    </div>

    {/* Drawn words with PlayButton */}
    <div className="w-1/2 h-[50vh] overflow-y-auto bg-white shadow-md rounded-lg p-4">
      <h3 className="text-xl font-bold text-sky-900 mb-4 text-center">Drawn Words</h3>
      <ul>
        {drawnWords.map(({ word, drawOrder }) => (
          <li key={word} className="p-2 text-lg border-b border-gray-200 flex justify-between items-center">
            {drawOrder}. {word}
            <PlayButton word={word} />
          </li>
        ))}
      </ul>
    </div>
  </div>
</div>

  );
}

export default BingoApp;
