import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FiTrash2, FiEye } from 'react-icons/fi'; // Icons for better visuals

function DatasetCard({ dataset, onDelete }) {
  const navigate = useNavigate(); // Use navigate for navigation instead of window.location

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this dataset?')) {
      onDelete(dataset.file_name);
    }
  };

  const handleView = () => {
    navigate(`/api_bingo/dataset/${encodeURIComponent(dataset.file_name)}`);
  };

  return (
    <div className="bg-white shadow-lg hover:shadow-xl rounded-lg p-4 flex flex-col justify-between transition-shadow duration-200">
      <h3 className="text-lg font-bold text-gray-900 mb-2">{dataset.display_name}</h3>
      <p className="text-sm text-gray-500">
        Uploaded at: {new Date(dataset.uploaded_at * 1000).toLocaleString()}
      </p>
      <div className="mt-4 flex justify-between">
        <button
          className="bg-blue-500 text-white py-2 px-4 rounded-lg flex items-center space-x-2 hover:bg-blue-600 transition"
          onClick={handleView}
        >
          <FiEye size={18} />
          <span>View</span>
        </button>
        <button
          className="bg-red-500 text-white py-2 px-4 rounded-lg flex items-center space-x-2 hover:bg-red-600 transition"
          onClick={handleDelete}
        >
          <FiTrash2 size={18} />
          <span>Delete</span>
        </button>
      </div>
    </div>
  );
}

export default DatasetCard;
