import React, { useState } from 'react';
import { FaDatabase, FaClipboardList, FaGamepad } from 'react-icons/fa'; // Icons for tabs

const InfoPage = () => {
  const [activeTab, setActiveTab] = useState(1);

  // Content for each step
  const steps = [
    {
      id: 1,
      title: "Create or Pick a Dataset",
      icon: <FaDatabase />,
      content: (
        <div className="text-gray-700 leading-relaxed">
          <h2 className="text-2xl font-semibold mb-4">Step 1: Create or Pick a Dataset</h2>
          <p className="mb-2">
            The first step is to prepare the words you’ll use in your Bingo game.
          </p>
          <h3 className="text-xl font-bold mb-2">Create a New Dataset:</h3>
          <ul className="list-disc list-inside mb-4">
            <li>Navigate to the <strong>Datasets</strong> page.</li>
            <li>Click <strong>Create Dataset</strong> to start building a new dataset.</li>
            <li>Add a <strong>Dataset Name</strong>.</li>
            <li>Add <strong>Columns (Modes)</strong> such as Base, Past Simple, etc.</li>
            <li>Add rows with words for each column.</li>
            <li>Save your dataset.</li>
          </ul>
          <h3 className="text-xl font-bold mb-2">Pick an Existing Dataset:</h3>
          <ul className="list-disc list-inside">
            <li>Go to the <strong>Datasets</strong> page to see a list of available datasets.</li>
            <li>Select a dataset for use in card generation or the Bingo game.</li>
          </ul>
        </div>
      ),
    },
    {
      id: 2,
      title: "Generate Bingo Cards",
      icon: <FaClipboardList />,
      content: (
        <div className="text-gray-700 leading-relaxed">
          <h2 className="text-2xl font-semibold mb-4">Step 2: Generate Bingo Cards</h2>
          <p className="mb-2">
            Once your dataset is ready, you can generate Bingo cards.
          </p>
          <ul className="list-disc list-inside">
            <li>Select a <strong>Dataset</strong> to use for card generation.</li>
            <li>Choose a <strong>Mode (Column)</strong> that provides the words.</li>
            <li>Select the <strong>Grid Size</strong> for your Bingo cards (e.g., 3x3, 4x4).</li>
            <li>Enter the number of Bingo cards you want to generate.</li>
            <li>Click <strong>Generate Bingo Cards</strong> to preview and download them as a PDF.</li>
          </ul>
        </div>
      ),
    },
    {
      id: 3,
      title: "Play the Bingo Game",
      icon: <FaGamepad />,
      content: (
        <div className="text-gray-700 leading-relaxed">
          <h2 className="text-2xl font-semibold mb-4">Step 3: Play the Bingo Game</h2>
          <p className="mb-2">Now that your cards are ready, it's time to play Bingo!</p>
          <ul className="list-disc list-inside">
            <li>Go to the <strong>Bingo Game</strong> page.</li>
            <li>Select a <strong>Dataset</strong> and a <strong>Mode</strong> for the game.</li>
            <li>Set a <strong>Timer</strong> if desired.</li>
            <li>Click <strong>Start New Game</strong> to shuffle and draw words.</li>
            <li>Click <strong>Draw</strong> to select and display a word on a popup.</li>
            <li>You can also play a pronunciation of the word during the game.</li>
          </ul>
        </div>
      ),
    },
  ];

  return (
    <div className="min-h-screen bg-blue-400 p-10">
      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6">
        <h1 className="text-3xl font-bold text-center text-sky-900 mb-6">How to Use the Bingo App</h1>

        {/* Tab Navigation */}
        <div className="flex justify-center mb-6">
          {steps.map((step) => (
            <button
              key={step.id}
              className={`flex items-center px-4 py-2 mx-2 text-lg font-medium rounded-lg transition-all duration-300 ${
                activeTab === step.id
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-200 text-sky-900 hover:bg-blue-300 hover:text-white'
              }`}
              onClick={() => setActiveTab(step.id)}
            >
              <span className="mr-2">{step.icon}</span>
              {step.title}
            </button>
          ))}
        </div>

        {/* Tab Content */}
        <div className="p-6 bg-gray-100 rounded-lg shadow-inner">
          {steps.find((step) => step.id === activeTab)?.content}
        </div>
      </div>
    </div>
  );
};

export default InfoPage;
