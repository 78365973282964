import React from 'react';
import { MdOutlineDraw } from 'react-icons/md'; // Import the draw icon

function DrawButton({ onDraw }) {
  return (
    <div className="flex justify-center items-center">
      <button
        onClick={onDraw}
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg flex items-center"
      >
        <MdOutlineDraw className="mr-2" size={24} /> {/* Icon with some margin */}
        Draw Word
      </button>
    </div>
  );
}

export default DrawButton;
