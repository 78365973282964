import React, { useState } from 'react';
import { api } from '../auth/auth'; // Adjusted import

function CSVUpload({ onUploadSuccess }) {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setMessage('');
  };

  const handleUpload = async () => {
    if (!file) {
      setMessage('Please select a CSV file to upload.');
      return;
    }

    setUploading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      await api.post('/api_bingo/upload_csv/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage('File uploaded successfully.');
      setFile(null);
      onUploadSuccess(); // Refresh the dataset list
    } catch (error) {
      console.error('Error uploading file:', error);
      setMessage('Upload failed.');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="mb-6">
      <h2 className="text-xl font-bold mb-2">Upload New Dataset</h2>
      <div className="flex items-center">
        <input type="file" accept=".csv" onChange={handleFileChange} />
        <button
          onClick={handleUpload}
          disabled={uploading}
          className="bg-green-500 text-white py-2 px-4 rounded ml-2"
        >
          {uploading ? 'Uploading...' : 'Upload CSV'}
        </button>
      </div>
      {message && <p className="mt-2">{message}</p>}
    </div>
  );
}

export default CSVUpload;
