import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '../auth/auth'; // Adjusted import
import { parse, unparse } from 'papaparse';
import { FaDatabase } from 'react-icons/fa';

function DatasetDetailPage() {
  const { fileName } = useParams();
  const [columns, setColumns] = useState([]);
  const [dataRows, setDataRows] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    const fetchDataset = async () => {
      try {
        const encodedFileName = encodeURIComponent(fileName);
        const response = await api.get(`/api_bingo/datasets/${encodedFileName}/`, {
          responseType: 'blob',
        });
        const textData = await response.data.text();
        parse(textData, {
          header: true,
          complete: (result) => {
            setDataRows(result.data);
            setOriginalData(result.data);
            setColumns(result.meta.fields);
          },
        });
      } catch (error) {
        console.error('Error fetching dataset:', error);
      }
    };

    fetchDataset();
  }, [fileName]);

  // Handle input changes in table cells
  const handleCellChange = (e, rowIndex, columnName) => {
    const newValue = e.target.value;
    const updatedRows = [...dataRows];
    updatedRows[rowIndex][columnName] = newValue;
    setDataRows(updatedRows);
    setIsModified(true);
  };

  // Add a new row
  const handleAddRow = () => {
    const newRow = {};
    columns.forEach((column) => {
      newRow[column] = '';
    });
    setDataRows([...dataRows, newRow]);
    setIsModified(true);
  };

  // Delete a row
  const handleDeleteRow = (rowIndex) => {
    const updatedRows = dataRows.filter((_, index) => index !== rowIndex);
    setDataRows(updatedRows);
    setIsModified(true);
  };

  // Save changes to the server
  const handleSaveChanges = async () => {
    if (!isModified) {
      alert('No changes to save.');
      return;
    }

    // Convert the updated rows back to CSV format
    const csvContent = unparse(dataRows);

    try {
      // Send the updated CSV content to the server
      await api.put(`/api_bingo/datasets/${fileName}/`, { csv_content: csvContent });

      alert('Dataset updated successfully!');
      setIsModified(false); // Reset the modified state after saving
    } catch (error) {
      console.error('Error saving changes:', error);
      alert('Failed to update dataset.');
    }
  };

  // Cancel changes
  const handleCancelChanges = () => {
    if (window.confirm('Are you sure you want to discard changes?')) {
      setDataRows(originalData);
      setIsModified(false);
    }
  };

  return (
    <div className="p-6 bg-blue-400 min-h-screen">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">{fileName}</h1>
  
      {/* Buttons for saving and adding rows */}
      <div className="mb-6 flex space-x-4">
        <button
          className="bg-green-600 hover:bg-green-500 text-white font-semibold py-2 px-4 rounded-lg"
          onClick={handleAddRow}
        >
          Add Row
        </button>
        {isModified && (
          <>
            <button
              className="bg-blue-600 hover:bg-blue-500 text-white font-semibold py-2 px-4 rounded-lg"
              onClick={handleSaveChanges}
            >
              Save Changes
            </button>
            <button
              className="bg-gray-600 hover:bg-gray-500 text-white font-semibold py-2 px-4 rounded-lg"
              onClick={handleCancelChanges}
            >
              Cancel
            </button>
          </>
        )}
      </div>
  
      {/* Display modes (columns) */}
      <div className="mb-6">
      <h2 className="text-xl font-semibold mb-4 text-sky-900">Modes (Columns)</h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
        {columns.map((column) => (
          <div key={column} className="bg-white shadow-md rounded-lg p-4 flex items-center space-x-4">
            {/* Icon */}
            <div className="text-sky-500">
              <FaDatabase className="w-6 h-6" />
            </div>
            {/* Column name */}
            <div className="text-sky-900 font-semibold">{column}</div>
          </div>
        ))}
      </div>
    </div>
      {/* Display and edit words (data rows) */}
      <h2 className="text-xl font-semibold mb-4 text-sky-900">Words (Data Rows)</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto bg-sky-900 shadow-md rounded-lg">
          <thead className="bg-gray-200">
            <tr>
              <th className="px-6 py-3 text-left text-sky-900 font-semibold border-b">#</th>
              {columns.map((column) => (
                <th key={column} className="px-6 py-3 text-left text-sky-900 font-semibold border-b">
                  {column}
                </th>
              ))}
              <th className="px-6 py-3 text-left text-sky-900 font-semibold border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {dataRows.map((row, rowIndex) => (
              <tr key={rowIndex} className="hover:bg-sky-700">
                <td className="px-6 py-4 border-t text-gray-100 text-center">{rowIndex + 1}</td>
                {columns.map((column) => (
                  <td key={column} className="px-6 py-4 border-t">
                    <input
                      type="text"
                      value={row[column]}
                      onChange={(e) => handleCellChange(e, rowIndex, column)}
                      className="w-full border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    />
                  </td>
                ))}
                <td className="px-6 py-4 border-t text-center">
                  <button
                    className="bg-red-500 hover:bg-red-400 text-white font-semibold py-1 px-3 rounded-lg"
                    onClick={() => handleDeleteRow(rowIndex)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
  


}

export default DatasetDetailPage;
