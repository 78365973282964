import React, { useState, useEffect } from 'react';

function Timer({ initialTime, onReset }) {
  const [remainingTime, setRemainingTime] = useState(initialTime);
  const [timerRunning, setTimerRunning] = useState(false);

  useEffect(() => {
    let timer;
    if (timerRunning && remainingTime > 0) {
      timer = setTimeout(() => setRemainingTime(remainingTime - 1), 1000);
    } else if (remainingTime === 0 && timerRunning) {
      setTimerRunning(false);
      alert("Time's up!");
    }
    return () => clearTimeout(timer);
  }, [timerRunning, remainingTime]);

  // Update remainingTime when initialTime changes
  useEffect(() => {
    setRemainingTime(initialTime);
  }, [initialTime]);

  const startTimer = () => {
    setTimerRunning(true);
  };

  const stopTimer = () => {
    setTimerRunning(false);
  };

  const resetTimer = () => {
    setRemainingTime(initialTime);
    setTimerRunning(false);
    if (onReset) onReset();  // Notify parent component if needed
  };

  return (
<div className="mt-6 w-full max-w-md text-center">
  <h3 className="text-2xl font-bold text-gray-800 mb-4">
    Timer: {Math.floor(remainingTime / 60)}:
    {String(remainingTime % 60).padStart(2, '0')}
  </h3>
  <div className="flex justify-center space-x-4">
    <button 
      onClick={startTimer} 
      className="bg-green-600 hover:bg-green-500 text-white font-semibold py-2 px-6 rounded-lg shadow-md transition duration-200"
    >
      Start
    </button>
    <button 
      onClick={stopTimer} 
      className="bg-red-600 hover:bg-red-500 text-white font-semibold py-2 px-6 rounded-lg shadow-md transition duration-200"
    >
      Stop
    </button>
    <button 
      onClick={resetTimer} 
      className="bg-yellow-600 hover:bg-yellow-500 text-white font-semibold py-2 px-6 rounded-lg shadow-md transition duration-200"
    >
      Reset
    </button>
  </div>
</div>

  );
}

export default Timer;
