import React from 'react';

function VerbFormSelector({ options, setSelectedForm }) {
  const handleChange = (e) => {
    setSelectedForm(e.target.value);
  };

  return (
<div className="mb-6 w-full max-w-md">
  <label htmlFor="verbFormSelector" className="block text-lg font-semibold text-gray-700 mb-2">
    Select Mode:
  </label>
  <select
    id="verbFormSelector"
    onChange={handleChange}
    className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring focus:ring-sky-300 shadow-sm"
  >
    <option value="">--Select Mode--</option>
    {options.map((option) => (
      <option key={option} value={option}>
        {option}
      </option>
    ))}
  </select>
</div>

  );
}

export default VerbFormSelector;
