import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaHome, FaTable, FaGamepad, FaThLarge, FaInfoCircle, FaBars, FaTimes } from 'react-icons/fa';
import { FiLogIn, FiLogOut } from 'react-icons/fi';

const Navbar = ({ token, onLogout }) => {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="bg-blue-600 p-4 text-white flex justify-between items-center">
      {/* Left Section: Logo and Links */}
      <div className="flex items-center space-x-4">
        {/* Home Link */}
        <Link to="/" className="flex items-center space-x-1 hover:text-gray-200 transition">
          <FaHome className="text-xl" />
          <span>Home</span>
        </Link>

        {/* Desktop Menu Links */}
        <div className="hidden md:flex items-center space-x-4">
          {token && (
            <>
              <Link
                to="/datasets"
                className="flex items-center space-x-1 hover:text-gray-200 transition"
              >
                <FaTable className="text-xl" />
                <span>Datasets</span>
              </Link>

              <Link
                to="/bingo-app"
                className="flex items-center space-x-1 hover:text-gray-200 transition"
              >
                <FaGamepad className="text-xl" />
                <span>Bingo App</span>
              </Link>

              <Link
                to="/card-generator"
                className="flex items-center space-x-1 hover:text-gray-200 transition"
              >
                <FaThLarge className="text-xl" />
                <span>Card Generator</span>
              </Link>

              {/* Info Link */}
              <Link
                to="/info"
                className="flex items-center space-x-1 hover:text-gray-200 transition"
              >
                <FaInfoCircle className="text-xl" />
                <span>Info</span>
              </Link>
            </>
          )}
        </div>
      </div>

      {/* Right Section: Login/Logout Button */}
      <div className="hidden md:block">
        {token ? (
          <button
            onClick={handleLogout}
            className="flex items-center space-x-2 bg-red-500 hover:bg-red-600 text-white py-1 px-3 rounded transition"
          >
            <FiLogOut className="text-xl" />
            <span>Logout</span>
          </button>
        ) : (
          <Link
            to="/login"
            className="flex items-center space-x-2 bg-green-500 hover:bg-green-600 py-1 px-3 rounded transition"
          >
            <FiLogIn className="text-xl" />
            <span>Login</span>
          </Link>
        )}
      </div>

      {/* Mobile Menu Icon */}
      <div className="md:hidden flex items-center">
        <button onClick={toggleMobileMenu} className="text-2xl focus:outline-none">
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </button>
      </div>

      {/* Full-Screen Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="fixed inset-0 bg-blue-600 z-50 flex flex-col justify-center items-center text-white space-y-8">
          <Link
            to="/"
            className="text-2xl flex items-center space-x-1 hover:text-gray-200 transition"
            onClick={toggleMobileMenu}
          >
            <FaHome className="text-3xl" />
            <span>Home</span>
          </Link>

          {token && (
            <>
              <Link
                to="/datasets"
                className="text-2xl flex items-center space-x-1 hover:text-gray-200 transition"
                onClick={toggleMobileMenu}
              >
                <FaTable className="text-3xl" />
                <span>Datasets</span>
              </Link>

              <Link
                to="/bingo-app"
                className="text-2xl flex items-center space-x-1 hover:text-gray-200 transition"
                onClick={toggleMobileMenu}
              >
                <FaGamepad className="text-3xl" />
                <span>Bingo App</span>
              </Link>

              <Link
                to="/card-generator"
                className="text-2xl flex items-center space-x-1 hover:text-gray-200 transition"
                onClick={toggleMobileMenu}
              >
                <FaThLarge className="text-3xl" />
                <span>Card Generator</span>
              </Link>

              <Link
                to="/info"
                className="text-2xl flex items-center space-x-1 hover:text-gray-200 transition"
                onClick={toggleMobileMenu}
              >
                <FaInfoCircle className="text-3xl" />
                <span>Info</span>
              </Link>
            </>
          )}

          {token ? (
            <button
              onClick={handleLogout}
              className="text-2xl flex items-center space-x-2 bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded transition"
            >
              <FiLogOut className="text-3xl" />
              <span>Logout</span>
            </button>
          ) : (
            <Link
              to="/login"
              className="text-2xl flex items-center space-x-2 bg-green-500 hover:bg-green-600 py-2 px-4 rounded transition"
              onClick={toggleMobileMenu}
            >
              <FiLogIn className="text-3xl" />
              <span>Login</span>
            </Link>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
